const expandButtons = document.querySelectorAll('.expandBtn');
const collapseButtons = document.querySelectorAll('.collapseBtn');

expandButtons.forEach((btn, index) => {
  btn.addEventListener('click', (e) => {
    e.preventDefault();

    const container = document.getElementById(`seo-block-${index + 1}`);
    const blocks = container.querySelectorAll('.descriptionBasicTextHide');

    blocks.forEach((el) => {
      el.classList.remove('descriptionBasicTextHide');
    });

    btn.classList.add('hide');
  })
})

collapseButtons.forEach((btn, index) => {
  btn.addEventListener('click', (e) => {
    e.preventDefault();

    const container = document.getElementById(`seo-block-${index + 1}`);
    const blocks = container.querySelectorAll('.descriptionBasicText');

    blocks.forEach((el, index) => {
      if (index === 0) {
        el.querySelector('.expandBtn').classList.remove('hide');
      }
      if (index !== 0) {
        el.classList.add('descriptionBasicTextHide');
      }
    });
  })
})
